import React from 'react';

export default () => (
  <>
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `
      {
          "@context": "https://schema.org/",
          "@type": "SoftwareApplication",
          "name": "Polypane browser for ambitious web developers",
          "operatingSystem": "Windows, Macos, Linux",
          "applicationCategory": "DeveloperApplication",
          "description": "Build better websites in less time with a stand-alone browser that makes your site more responsive, more accessible and faster.",
          "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "5",
          "ratingCount": "89"
        },
        "offers": {
            "@type": "AggregateOffer",
            "url": "https://polypane.app/pricing/",
            "priceCurrency": "USD",
            "lowPrice": "9",
            "highPrice": "49",
            "offerCount": "4"
          }
        }
  `,
      }}
    />
    <script
      type="application/ld+json"
      id="schema"
      dangerouslySetInnerHTML={{
        __html: `
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://polypane.app",
      "logo": {
        "@type": "ImageObject",
        "url": "https://polypane.app/general/logo.png",
        "width": 5620,
        "height": 1200
      },
      "name": "Polypane",
      "legalName": "Polypane BV",
      "description": "The browser for ambitious web developers. Build better websites in less time with a stand-alone browser that makes your site more responsive, more accessible and faster.",
      "foundingDate": "2019",
      "founders": [
        {
          "givenName": "Kilian",
          "familyName": "Valkhof"
        }
      ],
      "sameAs": [
        "https://www.facebook.com/PolypaneBrowser",
        "https://twitter.com/polypane",
        "https://www.instagram.com/polypane",
        "https://www.linkedin.com/company/polypane"
      ]
    }
  `,
      }}
    />
  </>
);
