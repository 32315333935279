import { css } from 'astroturf';
import React from 'react';
import Punchout from './Punchout';

const styles = css`
  .about {
    text-align: center;
    padding: 5rem 2rem 5rem;
    padding-left: calc(2rem + env(safe-area-inset-left, 0));
    padding-right: calc(2rem + env(safe-area-inset-right, 0));
    max-width: calc(1200px + 4rem);
    margin-left: auto;
    margin-right: auto;
    color: #0a1530;
    --white: #fff;
    --fill: #0a1530;

    &.dark {
      color: #fff;

      --white: #0a1530;
      --fill: #fff;
    }

    &.nomargin {
      margin-top: 0;
      padding-top: 4.5rem;
    }

    & h2 {
      font-weight: 900;
      font-size: clamp(18px, 16px + 4vw, 44px);
      letter-spacing: -0.015em;
      line-height: 1.2;
      margin: auto;
      margin-bottom: 1rem;
      margin-top: 0;
      max-width: 60rem;
      color: #0a1530;
    }

    @media (max-width: 480px) {
      & svg {
        height: 40px;
      }
    }

    & h3 {
      font-weight: 900;
      font-size: clamp(16px, 16px + 3vw, 32px);
      line-height: 1.25;
      margin: 0;
    }
    & p {
      font-size: calc(16px + 0.25vw);
      font-weight: 400;
      position: relative;
      max-width: 52rem;
      margin: 0 auto;
      line-height: 1.75;
      @media (min-width: 770px) {
        font-size: 1.17rem;
      }
    }

    & > div {
      width: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      max-width: 1400px;
      margin: 0 auto;
      padding: 2rem 0 0rem;
      text-align: center;
      gap: 2rem;

      & p {
        max-width: 16rem;
      }

      @media (min-width: 800px) {
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: row;

        & div {
          padding: 2rem 0 0;
        }
      }
    }
  }
`;

function About(props) {
  return (
    <div
      className={[styles.about, props.nomargin ? styles.nomargin : '', props.dark ? styles.dark : ''].join(' ')}
      id="about"
    >
      <h2>
        All the tools you need to <Punchout>Build, Debug and Test</Punchout> sites
      </h2>
      <p>
        Develop and test every aspect of your site without context switching. From responsive design, to accessibility,
        to meta tags and performance, Polypane has you covered.
      </p>

      <div>
        <div>
          <svg width="137" height="60" viewBox="0 0 137 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="53" height="60" rx="8" fill="var(--fill)" />
            <rect x="60" width="43" height="50" rx="8" fill="var(--fill)" />
            <rect x="110" width="27" height="37" rx="8" fill="var(--fill)" />
          </svg>

          <h3>Responsive design</h3>
          <p>Mobile screens to 5K monitors, see all viewports in one overview.</p>
        </div>
        <div>
          <svg width="88" height="60" viewBox="0 0 88 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="87" height="60" rx="10" fill="var(--fill)" />
            <path
              d="M22.5 18.5C22.5 21 24.5 23 27 23C29.4375 23 31.5 21 31.5 18.5C31.5 16.0625 29.4375 14 27 14C24.5 14 22.5 16.0625 22.5 18.5ZM38.375 18.625C37.625 17.8125 36.3125 17.8125 35.5625 18.625L30.125 24H23.8125L18.375 18.625C17.625 17.8125 16.3125 17.8125 15.5625 18.625C14.75 19.375 14.75 20.6875 15.5625 21.4375L21.5 27.375V44C21.5 45.125 22.375 46 23.5 46H24.5C25.5625 46 26.5 45.125 26.5 44V37H27.5V44C27.5 45.125 28.375 46 29.5 46H30.5C31.5625 46 32.5 45.125 32.5 44V27.375L38.375 21.4375C39.1875 20.6875 39.1875 19.375 38.375 18.625Z"
              fill="var(--white)"
            />
            <path
              d="M54.5 18.5C54.5 21 56.5 23 59 23C61.4375 23 63.5 21 63.5 18.5C63.5 16.0625 61.4375 14 59 14C56.5 14 54.5 16.0625 54.5 18.5ZM70.375 18.625C69.625 17.8125 68.3125 17.8125 67.5625 18.625L62.125 24H55.8125L50.375 18.625C49.625 17.8125 48.3125 17.8125 47.5625 18.625C46.75 19.375 46.75 20.6875 47.5625 21.4375L53.5 27.375V44C53.5 45.125 54.375 46 55.5 46H56.5C57.5625 46 58.5 45.125 58.5 44V37H59.5V44C59.5 45.125 60.375 46 61.5 46H62.5C63.5625 46 64.5 45.125 64.5 44V27.375L70.375 21.4375C71.1875 20.6875 71.1875 19.375 70.375 18.625Z"
              fill="var(--white)"
            />
          </svg>

          <h3>Lint as you go</h3>
          <p>Check your structure, metadata and accessibility. Get instant suggestions.</p>
        </div>
        <div>
          <svg width="111" height="60" viewBox="0 0 111 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="111" height="60" rx="10" fill="var(--fill)" />
            <rect x="6" y="6" width="49" height="48" rx="6" fill="var(--white)" />
            <rect x="16" y="17" width="29" height="3" fill="var(--fill)" />
            <rect x="16" y="25" width="13" height="3" fill="var(--fill)" />
            <rect x="16" y="33" width="21" height="3" fill="var(--fill)" />
            <rect x="16" y="41" width="16" height="3" fill="var(--fill)" />
            <rect x="68" y="17" width="29" height="3" fill="var(--white)" />
            <rect x="68" y="25" width="13" height="3" fill="var(--white)" />
            <rect x="68" y="33" width="21" height="3" fill="var(--white)" />
            <rect x="68" y="41" width="16" height="3" fill="var(--white)" />
          </svg>

          <h3>Side by side views</h3>
          <p>Test dark and light and more without changing your system settings.</p>
        </div>
      </div>
    </div>
  );
}

export default About;
