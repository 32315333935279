import { css } from 'astroturf';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const styles = css`
  .appWrapper {
    z-index: 3;
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 1200px;
  }

  .app {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow:
      rgba(255, 255, 255, 0.25) 0px 1px 1px 0px inset,
      rgba(50, 50, 93, 0.6) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.7) 0px 30px 60px -30px,
      rgba(103, 178, 111, 0.6) -10px 10px 60px -10px;
  }
`;

export default () => (
  <div className={styles.appWrapper}>
    <StaticImage
      src="../../assets/images/hero/app4.png"
      className={styles.app}
      alt=""
      width="1200"
      loading="eager"
      placeholder="none"
      backgroundColor="#353447"
      formats={["png"]}
      quality="100"
    />
  </div>
);
