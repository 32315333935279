import { css } from 'astroturf';
import React from 'react';

const styles = css`
  .punchout {
    background: linear-gradient(135deg, #67b26f, ease-in-out, rgb(60, 176, 255));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    background-clip: text;
    box-decoration-break: clone;

    @media (color-gamut: p3) {
      background-image: linear-gradient(135deg, oklab(0.66 -0.24 0.16), oklab(0.61 -0.08 -0.21));
    }

    & span {
      color: #0a1530;
      -webkit-text-fill-color: #0a1530;
    }
  }
`;

const Punchout = ({ children }) => <span className={styles.punchout}>{children}</span>;

export default Punchout;
