import { css } from 'astroturf';
import React from 'react';

const styles = css`
  .brow {
    color: #007ca5;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    font-size: 0.8em;

    background: radial-gradient(#67b26f, ease-in-out, #007ca5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

function Brow({ color, children }) {
  return (
    <span className={styles.brow} style={color ? { color } : {}}>
      {children}
    </span>
  );
}

export default Brow;
